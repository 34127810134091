/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description 导航
 *@author meng wang
 *@date 2020/9/2
 */
import React, { useState, useEffect } from 'react'
import Link from 'next/link'

export default function Breadcrumb({course}) {
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  useEffect(() => {
    const path = window.location.pathname.replace(/\/[^/]*$/, '')
    switch (path) {
      case '/user/experts':
        setName('‹ 学术问答')
        break
      case '/user/life-questions':
        setName('‹ 学长领航')
        break
      case '/user/essayhelp':
        setName('‹ 作文润色')
        break
      case '/user/email-helper':
        setName('‹ 沟通辅导')
        break
      default:
        setName('')
    }
    setLink(path)
  }, [])
  function returnBack(e) {
    e.preventDefault()
    window.location.href = link
  }
  return (
    <ul className="c-list u-font-size-xs u-color-dark-lt">
      <li className="c-list__item c-list__item--inline">
        <a onClick={returnBack}>{name}</a>
      </li>
      {course && <li className="c-list__item c-list__item--inline">{course}</li>}
    </ul>
  )
}
