/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-script-url */
/**
 *@Description 问题内容
 *@author meng wang
 *@date 2020/9/4
 */
import React, { useState, useEffect } from 'react'
import { Btn } from '../../../common'

export default function CommentRating({rating = 0, ratingComment = '', rateStar, setRateStar, text, setText, status, evaluate}) {
  const [starWidth, setStarWidth] = useState(rating * 25 + 'px')
  const [textAreaStatus, setTextAreaStatus] = useState(false)

  useEffect(() => {
    setStarWidth(rating * 25 + 'px')
    if (rating) {
      setTextAreaStatus(false)
    }
  }, [rating])

  function setStar(e, type) {
    if (rating) return
    const cl = e.target.className
    const num = Number([...cl].pop()) + 1
    switch (type) {
      case 'click':
        setRateStar(num)
        setTextAreaStatus(num !== 5)
        setStarWidth(num * 25 + 'px !important')
        if (num === 5) {
          evaluate(null, 5)
        }
        break
      case 'mouseover':
        setStarWidth(num * 25 + 'px')
        break
      case 'mouseleave':
        setStarWidth(rateStar * 25 + 'px')
        break
      default:
        setStarWidth(0)
    }
  }

  return (
    <div className="p-qa__rate o-block o-block--shadow u-mb-m">
      <div className="p-qa__rate__title u-text-center">
        <h5 className="u-mb-xxs">
          {rating ? '感谢您的评价' : '您对答案满意吗？请给导师打分'}
        </h5>
        <p className="u-color-dark-lt u-font-size-base u-mb-xs">
          评价后问题将会关闭，而且评价不可被修改
        </p>
      </div>
      <form className="qa_rating_form">
        <div className="rating_star u-text-center starbox">
          <div className="positioner">
            <div className="stars">
              <div className="ghost" />
              <div className="colorbar" style={{width: starWidth, maxWidth: '120px'}} />
              <div
                className="star_holder"
                onClick={(e) => setStar(e, 'click')}
                onMouseOver={(e) => setStar(e, 'mouseover')}
                onMouseLeave={(e) => setStar(e, 'mouseleave')}
                onFocus={() => 0}
              >
                {
                  [0, 1, 2, 3, 4].map((i) => (
                    <div className={`star star-${i}`} key={i} />
                  ))
                }
              </div>
            </div>
          </div>
          {
            textAreaStatus && (
              <div className="o-block o-block--lt u-text-right u-p-xs">
                <div className="o-form-group rating_comment">
                  <textarea value={text} onChange={(e) => setText(e.target.value)} className="validate[required] c-form-field c-form-field--primary" placeholder="请在此给我们的导师提出意见" />
                </div>
                <Btn href="#" target="" cl="submit_qa-rating" content="提交" onClick={evaluate} />
              </div>
            )
          }
        </div>
        <div className="rating_comment u-mt-s">{ratingComment}</div>
      </form>
    </div>
  )
}
