/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description credits确认弹窗
 *@author meng wang
 *@date 2020/10/27
 */
import React from 'react'
import { Modal, Btn } from '../../common'

export default function ServiceApplication({submit, credits, close}) {
  function cancel(e) {
    e.preventDefault()
    close()
  }
  return (
    <Modal close={close}>
      <h1 className="c-modal__title u-mb-m">提交提问</h1>
      <p className="f4 mb15 js-confirmation_msg">
        是否确认使用
        {credits}
        提问？
      </p>
      <Btn content="取消" type="alt" outline cl="cancel" onClick={cancel} />
      &nbsp;
      <Btn content="确认提交" onClick={submit} />
    </Modal>
  )
}
