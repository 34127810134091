/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description add course form
 *@author meng wang
 *@date 2020/8/26
 */
import React, { useState, useEffect } from 'react'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import DatePicker from '@bit/nexxtway.react-rainbow.time-picker'
import {Select, Icon, Error } from '../../common'
import API from '../../../utils/api'

const SCHOOL_YEAR = [[2018, 2018], [2019, 2019]]
const SCHOOL_TERM = [['秋季学期(Sep-Dec)', '秋季学期(Sep-Dec)']]
const WEEKDAY = [['日', '7'], ['一', '1'], ['二', '2'], ['三', '3'], ['四', '4'], ['五', '5'], ['六', '6']]

export default function AddCourseForm({semester = 0, courses, professors, close}) {
  const [courseSearchValue, setCourseSearchValue] = useState('')
  const [professorSearchValue, setProfessorSearchValue] = useState('')
  const [courseOptions, setCourseOptions] = useState([])
  const [professorOptions, setProfessorOptions] = useState([])
  const [file, setFile] = useState([])
  const [courseReadonly, setCourseReadonly] = useState(true)
  const [professorReadonly, setProfessorReadonly] = useState(true)
  const [schoolTerm, setSchoolTerm] = useState(SCHOOL_TERM)
  const validationSchema = Yup.object().shape({
    oc_course_id: Yup.string().required('* 此处不可空白'),
    professor_id: Yup.string().required('* 此处不可空白'),
  })

  // 当course professor为空，input只读
  useEffect(() => {
    if (courses?.length) {
      setCourseReadonly(false)
    } else {
      setCourseReadonly(true)
    }
    if (professors?.length) {
      setProfessorReadonly(false)
    } else {
      setProfessorReadonly(true)
    }
    if (semester === 2) {
      setSchoolTerm(SCHOOL_TERM.concat([['春季学期(Jan-Mar)', '春季学期(Jan-Mar)']]))
    } else if (semester === 3) {
      setSchoolTerm(SCHOOL_TERM.concat([['冬季学期(Jan-Mar)', '冬季学期(Jan-Mar)'], ['春季学期(Mar-Jun)', '春季学期(Mar-Jun)']]))
    }
  }, [semester, courses, professors])

  // 选择文件
  function fileChoose(e) {
    const files = []
    for (let i = 0; i < e.target.files.length; ++i) {
      files.push(e.target.files[i])
    }
    setFile(files)
  }

  function selectChange(label, value, name, fn) {
    fn(name, value)
    if (name === 'oc_course_id') {
      setCourseSearchValue(label)
      fn('course_code', label)
    } else {
      setProfessorSearchValue(label)
      fn('professor_name', label)
    }
  }

  // 筛选options
  function filterOptions(label, value) {
    if (label === 'oc_course_id') {
      setCourseSearchValue(value)
      const options = courses.filter((option) => option.code.includes(value))
      setCourseOptions(options.map((option) => [option.code, option.id]))
    } else {
      setProfessorSearchValue(value)
      const options = professors.filter((option) => option.name.includes(value))
      setProfessorOptions(options.map((option) => [option.name, option.id]))
    }
  }

  // input change
  function handleInput(e, label, fn) {
    fn(label, e.target.value)
    filterOptions(label, e.target.value)
  }

  async function submit(values) {
    if (!courses.map((option) => option.id).includes(values.oc_course_id)) {
      values.course_code = values.oc_course_id
      delete values.oc_course_id
    }
    if (!professors.map((option) => option.id).includes(values.professor_id)) {
      values.professor_name = values.professor_id
      delete values.professor_id
    }
    const formData = new FormData()
    Object.keys(values).forEach((key) => {
      if (key === 'weekday') {
        return
      }
      if (key === 'semester') {
        formData.append(`enrollment[${key}]`, schoolTerm.findIndex((t) => t[0] === values[key]) + 1)
      } else if (key === 'course_type' && values[key] === 'Lab/Tutorial/Other') {
        formData.append(`enrollment[${key}]`, 'Other')
      } else {
        formData.append(`enrollment[${key}]`, values[key])
      }
    })
    formData.append('weekday[]', parseInt(values.weekday, 10))
    if (file.length) {
      file.forEach((f) => {
        formData.append('syllabus[]', f)
      })
    }
    formData.append('redirect_to', '/user/experts')
    const { redirect_to } = await API.post('user/enrollments', formData)
    if (redirect_to) {
      window.location = redirect_to
    }
    window.location.reload()
  }

  // errorElement
  function errorElement(errors, touched) {
    if (typeof document === 'undefined') return null
    let ele = null
    const formElement = document.getElementById('add_course_form')
    if (errors.oc_course_id && touched.oc_course_id) {
      ele = <Error top={-34} error={errors.oc_course_id} />
    } else if (errors.professor_id && touched.professor_id) {
      ele = <Error top={formElement.children[7].offsetTop - formElement.children[0].offsetTop - 34} error={errors.professor_id} />
    }
    return ele
  }

  return (
    <div id="courseModal" className="c-modal fade">
      <div className="modal-dialog" style={{width: 600}}>
        <div className="modal-content">
          <div className="modal-header">
            <a className="close" data-dismiss="modal" onClick={close}>
              <Icon name="x" />
            </a>
            <h4>添加课程</h4>
            <p className="task_description gray f5-ns f6 tc mt05">
              请先添加课程，再添加该课程的考试作业日期
            </p>
          </div>
          <div className="modal-body">
            <Formik
              initialValues={{
                oc_course_id: '',
                year: SCHOOL_YEAR[0][0],
                semester: schoolTerm[0][1],
                weekday: '7',
                start_time: '18:00',
                end_time: '20:00',
                course_type: 'Lecture',
                professor_id: '',
                location: '',
              }}
              validationSchema={validationSchema}
              onSubmit={submit}
            >
              {({values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting}) => (
                <form id="add_course_form" className="course_add center js-enrollment__add-form ph4-ns">
                  <div style={{position: 'relative'}}>
                    {errorElement(errors, touched)}
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label htmlFor="course_code">课程代码</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <Select
                        name="oc_course_id"
                        onChange={(label, value) => selectChange(label, value, 'oc_course_id', setFieldValue)}
                        options={courseOptions}
                        inputField={{
                          placeholder: 'e.g. ECO100',
                          value: courseSearchValue,
                          onChange: (e) => handleInput(e, 'oc_course_id', setFieldValue),
                          className: 'validate[required] text-input wd-100 js-course__code-add',
                          readOnly: courseReadonly,
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>学年</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <Select
                        className="chosen-container-single"
                        name="year"
                        value={values.year}
                        options={SCHOOL_YEAR}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>学期</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <Select
                        className="chosen-container-single"
                        name="semester"
                        value={values.semester}
                        options={schoolTerm}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>星期</label>
                    </div>
                    <div className="wd-70 fl seekdays_list">
                      {
                        WEEKDAY.map((option, index) => (
                          <div className="week_day v-mid mr05 dib" key={index}>
                            <Field
                              name="weekday"
                              value={option[1]}
                              className={`validate[minCheckbox[1]] checkbox dib v-mid js-enrollment__weekday-${index}-new u-mt-0`}
                              type="radio"
                            />
                            &nbsp;
                            <label className="v-mid">{option[0]}</label>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>开始时间</label>
                    </div>
                    <div className="wd-70 fl seekdays_list">
                      <DatePicker name="startTime" value={values.start_time} onChange={(value) => setFieldValue('start_time', value)} />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>结束时间</label>
                    </div>
                    <div className="wd-70 fl weekdays_list">
                      <DatePicker name="endTime" value={values.end_time} onChange={(value) => setFieldValue('end_time', value)} />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>教授</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <Select
                        name="professor_id"
                        onChange={(label, value) => selectChange(label, value, 'professor_id', setFieldValue)}
                        options={professorOptions}
                        inputField={{
                          placeholder: 'e.g. Steve',
                          value: professorSearchValue,
                          onChange: (e) => handleInput(e, 'professor_id', setFieldValue),
                          className: 'validate[required] text-input wd-100 js-course__code-add',
                          readOnly: professorReadonly,
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>课程类型</label>
                    </div>
                    <div className="wd-70 fl course_type lh-copy">
                      {
                        ['Lecture', 'Online', 'Lab/Tutorial/Other'].map((option, index) => (
                          <div className="o-form-group" key={index}>
                            <Field
                              name="course_type"
                              value={option}
                              className="validate[required] v-mid course_type_radio mt0"
                              type="radio"
                            />
                            &nbsp;
                            {option}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>教室</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <input
                        placeholder="e.g. BA1210"
                        className="validate[required] text-input wd-100 js-course__code-add"
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb05 clearfix">
                    <div className="wd-30 fl">
                      <label>Syllabus</label>
                    </div>
                    <div className="wd-70 fl course_code_id">
                      <div
                        className="btn upload wd-100 btn-gray"
                        onClick={() => document.getElementById('add_course_main_point').click()}
                      >
                        上传课程大纲
                      </div>
                      <small className="gray">仅支持.pdf, .jpeg, .jpg, .png</small>
                      {
                        file.map((f, k) => (
                          <div className="filename" key={k}>{f.name}</div>
                        ))
                      }
                      <input
                        type="file"
                        hidden="hidden"
                        name="qa_question"
                        multiple="multiple"
                        onChange={fileChoose}
                        id="add_course_main_point"
                        accept="image/png, image/jpeg, image/jpg, .pdf"
                      />
                    </div>
                  </div>
                  <button type="submit" id="add_course_virtual_btn" hidden="hidden" onClick={handleSubmit}> </button>
                </form>
              )}
            </Formik>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn" onClick={() => document.getElementById('add_course_virtual_btn').click()}>
              保存
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
