/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description 学术问答服务申请
 *@author meng wang
 *@date 2020/10/26
 */
import React, { useState, useEffect } from 'react'
import { Modal, Select, Btn, Error } from '../../common'
import API from '../../../utils/api'

export default function ServiceApplication({courses, close}) {
  const [course, setCourse] = useState({name: '选择课程', id: ''})
  const [options, setOptions] = useState([])
  const [modalStatus, setModalStatus] = useState(false)
  const [ifError, setIfError] = useState(false)

  useEffect(() => {
    if (courses.length) {
      setOptions(courses.map((c) => [c.code, c.id]))
    }
  }, [courses])

  async function submit(e) {
    e.preventDefault()
    if (!course.id) {
      setIfError(true)
      return
    }
    const {error} = await API.post('user/qa_questions/request_course_qa', {course_id: course.id})
    if (!error) {
      setModalStatus(true)
    }
  }

  return (
    <>
      <Modal hidden={modalStatus} close={close}>
        <h1 className="c-modal__title u-mb-m">学术问答服务申请</h1>
        <p className="c-modal__text u-color-dark">
          以下课程的科目并不在荃程无忧服务范围之内，但我们会尽最大努力尝试匹配对应的导师来为您答疑解问并辅导您的学习。
        </p>
        {
          ifError && (
            <div style={{position: 'relative'}}>
              <Error top={-16} error="* 此处不可空白" />
            </div>
          )
        }
        <Select
          value={course.name}
          onChange={(label, value) => { setIfError(false); setCourse({name: label, id: value}) }}
          className="chosen-container-single u-text-left double_vertical_margin"
          options={options}
        />
        <Btn size="lg" content="提交" onClick={submit} />
      </Modal>
      <Modal hidden={!modalStatus && 'hidden'} close={close}>
        <h1 className="c-modal__title u-mb-m">您的请求已提交</h1>
        <p className="c-modal__text u-color-dark">
          如果匹配导师成功，您将在7天内在学术问答页面看到该课程。如有任何问题，请联系您的学生发展导师进行咨询。
        </p>
      </Modal>
    </>
  )
}
