/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-script-url */
/**
 *@Description rating modal
 *@author meng wang
 *@date 2020/10/28
 */
import React, { useState } from 'react'
import { Modal, Btn, Error } from '../../common'

export default function RatingModal({question, evaluate, close}) {
  const [starWidth, setStarWidth] = useState('0px')
  const [textAreaStatus, setTextAreaStatus] = useState(false)
  const [rateStar, setRateStar] = useState(0)
  const [text, setText] = useState('')
  const [top, setTop] = useState()
  const [error, setError] = useState()
  const footer = () => (
    <>
      问题已关闭
      <br />
      {question?.content_string}
    </>
  )
  //  表单校验
  function formVerify() {
    if (!rateStar) {
      setTop(-28)
      setError('* 最小值为1')
      return false
    }
    if (rateStar !== 5 && !text) {
      setTop(8)
      setError('* 此处不可空白')
      return false
    }
    return true
  }

  function setStar(e, type) {
    const cl = e.target.className
    const num = Number([...cl].pop()) + 1
    switch (type) {
      case 'click':
        setRateStar(num)
        setError('')
        setTextAreaStatus(num !== 5)
        setStarWidth(`${num * 25}px !important`)
        break
      case 'mouseover':
        setStarWidth(`${num * 25}px`)
        break
      case 'mouseleave':
        setStarWidth(`${rateStar * 25}px`)
        break
      default:
        setStarWidth(0)
    }
  }

  function submit() {
    if (formVerify()) {
      evaluate(rateStar, text)
    }
  }

  return (
    <Modal close={close} width="80%" footer={footer()}>
      <h1 className="c-modal__title u-mb-m">请给解答上一条问题的导师评分</h1>
      <div className="p-qa__rate__title u-text-center">
        <p className="u-font-size-base">
          您对答案满意吗？请给导师打分
        </p>
      </div>
      <br />
      <form className="qa_rating_form">
        <div className="rating_star u-text-center starbox">
          <div style={{width: '40%', position: 'relative'}}>
            <Error error={error} top={top} />
          </div>
          <div className="positioner">
            <div className="stars">
              <div className="ghost" />
              <div className="colorbar" style={{width: starWidth, maxWidth: '120px'}} />
              <div
                className="star_holder"
                onClick={(e) => setStar(e, 'click')}
                onMouseOver={(e) => setStar(e, 'mouseover')}
                onMouseLeave={(e) => setStar(e, 'mouseleave')}
                onFocus={() => 0}
              >
                {
                  [0, 1, 2, 3, 4].map((i) => (
                    <div className={`star star-${i}`} key={i} />
                  ))
                }
              </div>
            </div>
          </div>
          <div className="o-block u-text-center u-p-xs">
            {
              textAreaStatus && (
                <div className="o-form-group rating_comment">
                  <textarea
                    value={text}
                    onChange={(e) => { setError(''); setText(e.target.value) }}
                    className="validate[required] c-form-field c-form-field--primary"
                    placeholder="请在此给我们的导师提出意见"
                  />
                </div>
              )
            }
            <Btn href="javascript:void(0)" target="" cl="submit_qa-rating" content="提交" onClick={submit} />
          </div>
        </div>
      </form>
    </Modal>
  )
}
