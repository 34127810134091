/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description recall confirm
 *@author meng wang
 *@date 2020/12/8
 */
import React from 'react'
import { Modal, Btn } from '../../common'

export default function RecallConfirm({submit, close}) {
  function cancel(e) {
    e.preventDefault()
    close()
  }
  return (
    <Modal width="40%" close={close}>
      <h1 className="c-modal__title u-mb-m f3">确认撤回问题吗？</h1>
      <p className="f4 mb15 js-confirmation_msg dark_gray">
        如若您确认撤回问题，将无法取消撤回
      </p>
      <Btn content="再想想" type="alt" outline cl="cancel" onClick={cancel} />
      &nbsp;&nbsp;
      <Btn content="确定撤回" onClick={submit} />
    </Modal>
  )
}
