/* eslint-disable react/no-danger */
/* eslint-disable */
/**
 *@Description 问题内容
 *@author meng wang
 *@date 2020/9/4
 */
import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { Icon } from '../../../common'
import bem from '../../../../utils/bem'
import { QUESTION_TYPES } from '../../../../utils/constant'

export default function QuestionInfo({question, attachments, relate}) {
  const [content, setContent] = useState(question?.content)
  const [format, setFormat] = useState(question?.content_format)

  useEffect(() => {
    if(question?.content_format) {
      setFormat(`<span style="font-weight: bold">[Format: ${question?.content_format}]${question.word_limitation ? `[Word Limitation: ${question.word_limitation || ''}]` : ''} </span>`)
    } else {
      setFormat('')
    }
    if (question?.content) {
      // mathMl2svg(question.content)
      setContent(question.content)
    }
  }, [question])

  return (
    <div className="c-ques qa_question_expanded js-qa_question_expanded">
      {
        question.specializations?.map((t) => (
          <fragment key={t}>
            <div className="c-tag c-tag--alt c-tag--md">
              {t}
            </div>
            &nbsp;
          </fragment>
        ))
      }
      {
        question.essay_options?.map((t) => (
          <fragment key={t}>
            <div className="c-tag c-tag--alt c-tag--md">{t}</div>
            &nbsp;
          </fragment>
        ))
      }
      {
        (question.question_type === QUESTION_TYPES.essay || question.question_type
          === QUESTION_TYPES.expert) && question.format_deadline && (
          <div className="mt05">
            <Icon name="clock" color="primary" size="square-xls" />
            &nbsp;Deadline:&nbsp;
            {question.format_deadline}
          </div>
        )
      }
      <div className="c-ques__title c-ques__title--lg question_info js-question_content question_content user_question_content">
        <div dangerouslySetInnerHTML={{__html: `${content?.slice(0, content.indexOf('>') + 1) || ''}${format || ''}${content?.slice(content.indexOf('>') + 1) || ''}`}} />
        <div className="c-ques__atm u-mt-xs">
          {
            attachments?.map((attachment) => (
              <a
                href={attachment.file}
                target="_blank"
                rel="noopener noreferrer"
                className="c-btn c-btn--outline--alt c-btn--sm"
                key={attachment.id}
              >
                <Icon name="/images/icons/attach2.svg" type="oc" cl="no-stroke u-mr-xxs" />
                {decodeURI(attachment.name)}
              </a>
            ))
          }
        </div>
      </div>
      <ul className="c-ques__info">
        {!relate && (
          <li className="c-ques__info__item c-ques__info__item--mdot c-ques__info__item--noafter js-qa-time">
            <div className="time_created_at">
              {bem.formatDate(question.created_at)}
            </div>
          </li>
        )}
        {question.status === 'answered' && question.question_type === QUESTION_TYPES.essay && (
          <div className="c-alert c-alert--alt p-qa__integrity u-mt-s">
            请根据导师的反馈建议仔细修改后再提交给教授。直接上交导师修改的论文将会涉及学术诚信问题。
          </div>
        )}
        {question.status === 'answered' && question.question_type === QUESTION_TYPES.expert && (
          <div className="c-alert c-alert--alt p-qa__integrity u-mt-s">
            请根据导师的思路或建议独立完成解题，直接上交导师的答案将会涉及学术诚信问题。
          </div>
        )}
      </ul>
    </div>
  )
}
