/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable quotes */
/**
 *@Description qa提示信息
 *@author meng wang
 *@date 2020/10/27
 */
import React, { useState, useEffect } from 'react'

export default function ServiceApplication({user, type}) {
  const [messageCount, setMessageCount] = useState(() => {})
  const [messageAttention, setMessageAttention] = useState('')
  useEffect(() => {
    const semester = user?.service_package?.semester ? '本学期' : '本学年'
    switch (type) {
      case 'expert':
        if (!user.is_2c) {
          if (user.service_package?.demo) {
            setMessageCount(() => `本账号可用次数剩余为：${user.expert_left}次`)
          } else {
            setMessageCount(() => `本月可用次数剩余为：${user.expert_left}次`)
          }
        } else {
          setMessageCount(() => (
            <>
              本账号可使用&nbsp;
              <strong>
                Credits：
                {user.expert_left}
              </strong>
            </>
          ))
        }
        setMessageAttention('请同学们提供完整的问题及必要的解题信息，我们专业的学术导师将会提供详细的解答思路和解题要点。')
        break
      case 'essay':
        if (!user.is_2c) {
          if (user.service_package?.language) {
            if (user?.north_america && !user.service_package?.demo) {
              setMessageCount(() => `${semester}作文润色与演讲稿可以修改的剩余次数为：${user.essay_left}次`)
            } else {
              setMessageCount(() => `本账号作文润色与演讲稿可以修改的剩余次数为：${user.essay_left}次`)
            }
          } else if (user?.north_america && !user.service_package?.demo) {
            setMessageCount(() => `${semester}可用作文润色的剩余次数为：${user.essay_left}次`)
          } else {
            setMessageCount(() => `本账号可用作文润色的剩余次数为：${user.essay_left}次`)
          }
          setMessageAttention(() => (
            <>
              请同学们上传已经完成的需要修改的论文或段落，论文导师和英语顾问会在72小时之内审阅并修改您提交的内容。
              <br />
              <strong>请注意：</strong>
              为了帮助您提高英文写作水平及遵循学术诚信的原则，作文润色服务并不能够为学生代写论文。
            </>
          ))
        } else {
          setMessageCount(() => (
            <>
              本账号可使用&nbsp;
              <strong>
                Credits：
                {user.essay_left}
              </strong>
            </>
          ))
        }
        break
      case 'life':
        if (user.north_america) {
          setMessageCount(() => `${semester}可用学长领航的剩余次数为：${user.life_left}次`)
        } else {
          setMessageCount(() => `本账号可用学长领航的剩余次数为：${user.life_left}次`)
        }
        break
      case 'prof':
        if (user.north_america) {
          setMessageCount(() => `${semester}沟通辅助的可用剩余次数为：${user.email_left}次。`)
        } else {
          setMessageCount(() => `本账户沟通辅助的可用剩余次数为：${user.email_left}次。`)
        }
        setMessageAttention('如有需要，请同学们附上教授的原始邮件，以便我们更好的帮你回复邮件。')
        break
      default:
    }
  }, [])
  return (
    <div className="p-qa__dclm__inner disclaimer">
      {messageCount}
      {type !== 'prof' ? <br /> : ''}
      {messageAttention}
    </div>
  )
}
