/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 *@Description 导师回答
 *@author meng wang
 *@date 2020/9/4
 */
import dynamic from 'next/dynamic'
import { Icon } from '../../../common'
import bem from '../../../../utils/bem'

const DynamicLazyComponent = dynamic(() => import('../../../common/math'), {
  ssr: false,
})

export default function Comments({comments = [], relate}) {
  return (
    <section id="js-qa-comments" className="js-qa-comments">
      {
        comments.length > 0 && comments.map((comment) => (
          <div className={`qa_comment_expanded solution ${(comment.solution || comment.tutor) ? 'c-comment--accent' : ''}`} key={comment.id}>
            <div className="l-container">
              <div className="l-row u-justify-content-center">
                <div className="l-col-md-9">
                  <div className="c-comment u-px-0 question_content js-qa-comment">
                    {comment.tutor
                    && (
                      <h6 className="c-comment__title c-comment__title--md">
                        导师答复
                        {comment.solution && <span className="u-color-primary-dk"> - 答案</span>}
                      </h6>
                    )}
                    {/* <div dangerouslySetInnerHTML={{__html: comment.content}} /> */}
                    <DynamicLazyComponent math={comment.content} />
                    <div className="u-mt-xs">
                      {
                        comment.qa_attachments?.length > 0 && comment.qa_attachments.map((attachment, k) => (
                          <div key={k}>
                            <a href={attachment.file} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--outline--alt c-btn--sm" key={attachment.id}>
                              <Icon name="/images/icons/attach2.svg" type="oc" cl="no-stroke u-mr-xxs" />
                              {decodeURI(attachment.name)}
                            </a>
                            &nbsp;
                          </div>
                        ))
                      }
                    </div>
                    {
                      !relate && comment.created_at && (
                        <div className="c-ques__info question_info">
                          <li className="c-ques__info__item c-ques__info__item--mdot js-qa-time">
                            <div className="time_created_at">
                              {bem.formatDate(comment.created_at)}
                            </div>
                          </li>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </section>
  )
}
