/**
 *@Description demo-timetable 弹窗
 *@author meng wang
 *@date 2020/11/4
 */
import React from 'react'
import { Modal, Btn } from '../../common'

export default function ServiceApplication({close}) {
  return (
    <Modal close={close}>
      <h4 className="modal-title">您正在使用体验版本</h4>
      <br />
      <div className="tc">
        <p className="f4 mb15">
          升级会员即可尊享所有服务并浏览所有内容
          <br />
          <br />
          <Btn href="/courses_landing" target="" content="了解服务" type="alt" />
          &nbsp;
          <Btn href="/payment" target="" content="升级会员" />
        </p>
      </div>
    </Modal>
  )
}
