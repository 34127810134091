/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
/**
 *@Description 导师回答
 *@author meng wang
 *@date 2020/9/4
 */
import React, { useState } from 'react'
import { Editor } from '../../../common'

export default function CommentForm({submit, text = '发表'}) {
  const [fileList, setFileList] = useState([])
  const [content, setContent] = useState('')

  return (
    <section className="u-pb-s u-pt-l">
      <div className="l-container">
        <div className="l-row u-justify-content-center">
          <div className="l-col-md-9">
            <div className="qa_question_post_comment">
              <div className="qa_comment_expanded">
                <p style={{margin: '0 0 1.5rem'}}>
                  <strong>如果你对导师的答案有任何疑问，可在此继续追问。</strong>
                  请注意，问题被评价或关闭后将不能进行追问。
                </p>
                <form className="qa_comment_post_comment_form">
                  <div className="c-text-editor c-text-editor--outline js-text-editor">
                    <Editor
                      init={{
                        toolbar1: 'code bold italic numlist link tiny_mce_wiris_formulaEditor ',
                      }}
                      initialValue=""
                      handleEditorChange={(value) => setContent(value)}
                      fileList={fileList}
                      setFileList={setFileList}
                      handleSubmit={() => submit(content, fileList)}
                      text={text}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
